@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&family=Play:wght@700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  iframe#webpack-dev-server-client-overlay {
    display: none !important;
  }
}

@layer base {
  html {
    @apply font-Montserrat;
  }

  h1 {
    @apply text-xl font-medium capitalize;
  }
}

.link {
  @apply p-2.5 flex rounded-md gap-6 items-center md:cursor-pointer cursor-default duration-300 font-medium text-gray-700;
}

.active {
  @apply bg-blue-100 text-blue-600;
}

.ck.ck-content ul,
.ck.ck-content ul li {
  list-style-type: inherit;
}
.ck.ck-content ul {
  padding-left: 40px;
}

.ck.ck-content ol,
.ck.ck-content ol li {
  list-style-type: decimal;
}
.ck.ck-content ol {
  padding-left: 40px;
}
.ck-editor__editable_inline {
  padding: 0 30px !important;
}

.ck-editor__editable_inline {
  min-height: 300px;
}
